<template>
  <div>
    <h1 class="mt-5">{{ $t('Anonymní obsah') }}</h1>
  </div>
</template>

<script>
import { /*state,*/mutations } from '@/store'

export default {
    components: { },

    computed: {
        /*state( ) {
            return state
        }*/
    },

    created( ) {
        mutations.readSetting( )
    },

    methods: { }
}
</script>